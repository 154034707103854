import * as React from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Menu,
  MenuItem,
  Drawer,
  IconButton,
  useMediaQuery,
  Theme,
  List,
  ListItemButton,
  ListItemText,
  Avatar,
  Collapse,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import { useContext, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../logo.jpg';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

interface NavItem {
  name: string;
  path?: string;
  action?: () => void;
  scopes: string[];
  children?: NavItem[];
}

const NavBar: React.FC = () => {
  // Separate states for profile and Users menus
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const { authenticatedUser, setAuthenticatedUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [nestedMenuOpen, setNestedMenuOpen] = useState<string | null>(null);

  const openProfileMenu = Boolean(profileAnchorEl);

  // Handle opening of the Profile menu
  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleNavItemMenu = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setMenuAnchorEl(event.currentTarget);
    setNestedMenuOpen(nestedMenuOpen === name ? null : name); // Toggle nested menu
  };

  const handleNavItemClose = () => {
    setMenuAnchorEl(null);
    setNestedMenuOpen(null);
  };

  const items: NavItem[] = [
    { name: 'EOD', path: '/EOD', scopes: ['SuperAdmin', 'Admin', 'DC', 'DC-Caledonia', 'dbowner'] },
    {
      name: 'Users',
      scopes: ['SuperAdmin', 'dbowner'],
      children: [
        { name: 'Register', path: '/user/register', scopes: ['SuperAdmin', 'dbowner'] },
        { name: 'Users', path: '/userList', scopes: ['SuperAdmin', 'dbowner'] },
      ],
    },
  ];

  const handleLogout = async () => {
    try {
      await fetch("/auth/logout", { credentials: "include" });
      setAuthenticatedUser({ _id: null, email: "", first_name: "", last_name: "", scope: "" });
      navigate("/user/login");
    } catch (e) {
      console.log(e);
    }
  };

  const firstNameInitial = authenticatedUser?.first_name?.[0] ?? '';
  const lastNameInitial = authenticatedUser?.last_name?.[0] ?? '';

  // Renders items for the Drawer in mobile view
  const renderNavItems = (items: NavItem[]) => (
    items
      .filter(item => authenticatedUser && item.scopes.includes(authenticatedUser.scope))
      .map((item) => (
        item.children ? (
          <React.Fragment key={item.name}>
            <ListItemButton onClick={() => setNestedMenuOpen(nestedMenuOpen === item.name ? null : item.name)}>
              <ListItemText primary={item.name} />
              {nestedMenuOpen === item.name ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </ListItemButton>
            <Collapse in={nestedMenuOpen === item.name} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderNavItems(item.children)}
              </List>
            </Collapse>
          </React.Fragment>
        ) : (
          <ListItemButton
            key={item.name}
            component={item.path ? Link : 'button'}
            to={item.path}
            onClick={() => {
              if (item.action) item.action();
              setDrawerOpen(false); // Close Drawer after navigation
            }}
          >
            <ListItemText primary={item.name} />
          </ListItemButton>
        )
      ))
  );

  const renderDesktopNavItem = (item: NavItem) => (
    <Button
      key={item.name}
      onClick={(e) => item.children ? handleNavItemMenu(e, item.name) : item.path ? navigate(item.path) : null}
      style={{ color: 'White', fontWeight: "bold", width: '100%', fontSize: '14pt' }}
    >
      {item.name}
      {item.children && (
        <Menu
          anchorEl={menuAnchorEl}
          open={nestedMenuOpen === item.name}
          onClose={handleNavItemClose}
        >
          {item.children.map((child) => (
            <MenuItem
              key={child.name}
              onClick={() => {
                handleNavItemClose();
                if (child.path) navigate(child.path);
              }}
            >
              {child.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Button>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Link to='/'>
              <img src={logo} alt="SVP-Sports" style={{ height: '50px', marginRight: '10px', cursor: 'pointer' }} />
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexGrow: 5, justifyContent: 'center' }}>
            {!isMobile && authenticatedUser && items.filter(item => item.scopes.includes(authenticatedUser.scope)).map(renderDesktopNavItem)}
          </Box>
          {authenticatedUser && authenticatedUser._id ? (
            <IconButton edge="end" color="inherit" onClick={handleProfileMenu}>
              <Avatar style={{ background: "#FFFFFF", color: "black", fontWeight: "bolder" }}>
                {firstNameInitial}{lastNameInitial}
              </Avatar>
            </IconButton>
          ) : (
            <Button color="inherit" onClick={() => navigate('/user/login')} style={{ fontWeight: "bold" }}>
              Login
            </Button>
          )}
          <Menu
            anchorEl={profileAnchorEl}
            open={openProfileMenu}
            onClose={handleProfileClose}
          >
            {authenticatedUser && authenticatedUser._id ? (
              <>
                <MenuItem onClick={() => { navigate('/user/profile'); handleProfileClose(); }}>Profile</MenuItem>
                <MenuItem onClick={() => { handleLogout(); handleProfileClose(); }}>Logout</MenuItem>
              </>
            ) : (
              <MenuItem onClick={() => { navigate('/user/login'); handleProfileClose(); }}>LogIn</MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setDrawerOpen(false)}
          onKeyDown={() => setDrawerOpen(false)}
        >
          <List>{renderNavItems(items)}</List>
        </Box>
      </Drawer>
    </>
  );
};

export default NavBar;
