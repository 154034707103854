import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import svp from '../../logo.jpg';
import { UserContext } from '../../App';
import { CssBaseline, Stack } from '@mui/material';
import NavBar from '../Navigation/Navbar';

interface IUserSessionInfo {
  _id: string | null;
  email: string;
  first_name: string;
  last_name: string;
  scope: string;
  location: string;
}

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));


export default function SignIn() {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const navigate = useNavigate();
  const {
    authenticatedUser,
    setAuthenticatedUser,
  }: { authenticatedUser: IUserSessionInfo; setAuthenticatedUser: any } =
    useContext(UserContext);

  // On component mount, check if a session exists
  useEffect(() => {
    const checkSession = async () => {
      try {
        let fetchRes = await fetch("/auth/user", {
          method: "POST",
          credentials: "include",
        });
        let response = await fetchRes.json();
        if (response.user && response.user._id) {
          setAuthenticatedUser(response.user);
          // Redirect based on user scope
          if (response.user.scope === "SuperAdmin" || response.user.scope === "Admin") {
            navigate("/");
          } else {
            navigate("/");
          }
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };
    checkSession();
  }, [setAuthenticatedUser, navigate]);

  // Handle manual login when session doesn't exist
  const handleLogin = async () => {
    try {
      let fetchRes = await fetch("/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginCredentials),
        credentials: "include",
      });
      let response = await fetchRes.json();
      if (response.message === "Success" && response.user._id) {
        setAuthenticatedUser(response.user);
        // Redirect based on user scope
        if (response.user.scope === "SuperAdmin" || response.user.scope === "Admin") {
          navigate("/");
        } else {
          navigate("/");
        }
      } else {
        setLoginErrorMessage(response.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // Validate inputs
  const validateInputs = () => {
    let isValid = true;

    if (!loginCredentials.email || !/\S+@\S+\.\S+/.test(loginCredentials.email)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!loginCredentials.password || loginCredentials.password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateInputs()) {
      handleLogin();
    }
  };

  return (
    <>
    <NavBar/>
        <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
    <Card variant="outlined">
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
     
        <img src={svp} style={{width: '700px', height:'140px'}}/>
      
      </Box>
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
      >
        Sign in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            error={emailError}
            helperText={emailErrorMessage}
            id="email"
            type="email"
            name="email"
            placeholder="your@email.com"
            autoComplete="email"
            autoFocus
            required
            fullWidth
            variant="outlined"
            value={loginCredentials.email}
            onChange={(e) =>
              setLoginCredentials((prev) => ({ ...prev, email: e.target.value }))
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password">Password</FormLabel>
          <TextField
            error={passwordError}
            helperText={passwordErrorMessage}
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            required
            fullWidth
            variant="outlined"
            value={loginCredentials.password}
            onChange={(e) =>
              setLoginCredentials((prev) => ({ ...prev, password: e.target.value }))
            }
          />
        </FormControl>
        <Button type="submit" fullWidth variant="contained">
          Sign in
        </Button>
        {loginErrorMessage && <Typography color="error">{loginErrorMessage}</Typography>}
      </Box>
    </Card>
    </SignInContainer>
    </>
  );
}