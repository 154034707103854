import { Route, Routes } from 'react-router-dom';


import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { createContext, useEffect, useState } from 'react';
import SignIn from './Components/sign-in/SignIn';
import NavBar from './Components/Navigation/Navbar';
import EODView from './Components/EOD/endOFtheDay';
import PrivateRoute from './Components/AuthModules/PrivateRoute';
import { RefreshContext } from './Components/AuthModules/RefreshContext';
import ProfilePage from './Components/AuthModules/Profile';
import RegisterPage from './Components/AuthModules/RegisterPage';
import UsersTable from './Components/AuthModules/userList';


const theme = createTheme({
	palette: {
		mode: 'light', // Light theme
		// You can customize the theme colors as needed
	},
});

// Define the shape of the context


// Initialize the UserContext with default values
export const UserContext = createContext<any>({});

function App() {

	const [refresh, setRefresh] = useState(false);
	const [authenticatedUser, setAuthenticatedUser] = useState<IUserSessionInfo>({
		_id: null,
		email: "",
		first_name: "",
		last_name: "",
		scope: "",
		location: "",
	});
	useEffect(() => {
		async function loadUserSession() {
			try {
				let fetchRes = await fetch("/auth/user", {
					method: "POST",
					credentials: "include",
				});
				let response = await fetchRes.json();
				setAuthenticatedUser((val) => response.user);
			} catch (e) {
				console.log(e);
			}
		}
		loadUserSession();
	}, []);


	return (
	
		<RefreshContext.Provider value={{ refresh, setRefresh }}>
		<UserContext.Provider value={{ authenticatedUser, setAuthenticatedUser }}>

			<ThemeProvider theme={theme}>

				<Routes>

					<Route path='/' element={<NavBar />} />
					<Route path='/EOD' element={<PrivateRoute page={<EODView />} />} />
					<Route path="/user">
						<Route path="login" element={<SignIn />} />
						<Route path="profile" element={<PrivateRoute page={<ProfilePage />} />} />
						<Route path="register" element={<PrivateRoute page={<RegisterPage />} />} />
					</Route>
					<Route path='/userList' element={<PrivateRoute page={<UsersTable />} />} />
				</Routes>
			</ThemeProvider>

		</UserContext.Provider>
	</RefreshContext.Provider>
		
	);
}

export default App;