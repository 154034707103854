// src/pages/RegisterPage.tsx
import React, { useState } from 'react';
import { Button, Card, Container, Grid, TextField, Typography, Alert } from '@mui/material';
import NavBar from '../Navigation/Navbar';

function RegisterPage() {
    const [registerCredentials, setRegisterCredentials] = useState({
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        location: "",
    });
    const [showRegistrationError, setShowRegistrationError] = useState(false);

    const handleRegister = async () => {
        setShowRegistrationError(false);
        if (!(registerCredentials.email.includes("@") && registerCredentials.email.includes("."))) {
            setShowRegistrationError(true);
            alert("Email must be valid");
            return;
        }
        if (
            registerCredentials.first_name === "" ||
            registerCredentials.last_name === "" ||
            registerCredentials.password === ""
        ) {
            setShowRegistrationError(true);
            return;
        }
        // Trim and capitalize first and last names
        const formattedCredentials = {
            ...registerCredentials,
            first_name: capitalizeFirstLetter(registerCredentials.first_name.trim()),
            last_name: capitalizeFirstLetter(registerCredentials.last_name.trim()),
            email: registerCredentials.email.trim(),
        };

        try {
            const response = await fetch("/auth/register", {
                method: "POST",
                body: JSON.stringify(formattedCredentials),
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                window.location.href = "/";
            } else {
                setShowRegistrationError(true);
            }
        } catch (e) {
            console.log(e);
            setShowRegistrationError(true);
        }
    };

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <><NavBar />
            <Container>

                <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>Register</Typography>
                <Card variant="outlined" sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                variant="outlined"
                                value={registerCredentials.first_name}
                                onChange={(e) => setRegisterCredentials({ ...registerCredentials, first_name: e.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                variant="outlined"
                                value={registerCredentials.last_name}
                                onChange={(e) => setRegisterCredentials({ ...registerCredentials, last_name: e.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Location"
                                variant="outlined"
                                value={registerCredentials.location}
                                onChange={(e) => setRegisterCredentials({ ...registerCredentials, location: e.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                type="email"
                                value={registerCredentials.email}
                                onChange={(e) => setRegisterCredentials({ ...registerCredentials, email: e.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Password"
                                variant="outlined"
                                type="password"
                                value={registerCredentials.password}
                                onChange={(e) => setRegisterCredentials({ ...registerCredentials, password: e.target.value })}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={handleRegister} fullWidth>
                                Register
                            </Button>
                        </Grid>
                    </Grid>
                    {showRegistrationError && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            All fields are required for registration. Please fill out all fields and try again.
                        </Alert>
                    )}
                </Card>
            </Container>
        </>
    );
}

export default RegisterPage;