// EODView.tsx
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SelectChangeEvent,
  TableContainer,
  Typography,
  Box,
  InputAdornment,
  
  TableRowProps,
} from '@mui/material';
import {
  format,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  parseISO,
} from 'date-fns';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import NavBar from '../Navigation/Navbar';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { exportToExcel } from './exportToExcel';

// Define the structure of data for TypeScript
interface ApiResponse {
  date: string;
  net_sales: number;
  hst: number;
  rebate8: number;
  statusIndianRebate8: number;
  gross_sales: number;
  actual_dpst: number | null;
  payments_by_tender: {
    tender_desc: string;
    total_payment_amount: number | null;
    amex_disc: number | null;
  }[];
}

const locations = [
  'Steeles',
  'New-Scarborough',
  'Brampton',
  'Newmarket',
  'Dufferin-Orfus',
  'Vaughan',
  'Queensway',
  'Niagara',
  'Alliston',
  'OPM-Hamilton',
  'Pickering',
  'Cartwright',
  'Yorkgate',
];

// Custom theme
const theme = createTheme({
  typography: {
    fontFamily: 'Arial, sans-serif', // Set default font to Arial
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '4px 8px',
          whiteSpace: 'nowrap',
          border: '1px solid rgba(224, 224, 224, 1)', // Add borders to cells
          fontFamily: 'Arial, sans-serif', // Ensure Arial font
          textAlign: 'center', // Center-align text
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            padding: '2px 4px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '0.875rem',
            padding: '4px 8px',
          },
        }),
        head: ({ theme }) => ({
          backgroundColor: '#f5f5f5',
          fontWeight: 'bold',
          border: '1px solid rgba(224, 224, 224, 1)',
          whiteSpace: 'nowrap',
          fontFamily: 'Arial, sans-serif', // Ensure Arial font
          textAlign: 'center', // Center-align header text
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            padding: '2px 4px',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '0.875rem',
            padding: '4px 8px',
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'Arial, sans-serif', // Ensure Arial font
          textAlign: 'center', // Center-align input text
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '0.875rem',
          },
        }),
        input: {
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&[type=number]': {
            '-moz-appearance': 'textfield',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'Arial, sans-serif', // Ensure Arial font
          textAlign: 'center', // Center-align button text
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '0.875rem',
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          fontFamily: 'Arial, sans-serif', // Ensure Arial font
          textAlign: 'center', // Center-align select text
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '0.875rem',
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'Arial, sans-serif', // Ensure Arial font
          textAlign: 'center', // Center-align labels
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: '0.875rem',
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial, sans-serif', // Ensure Arial font
          textAlign: 'center', // Center-align typography
        },
      },
    },
  },
});

// Styled components
const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '84vh', // Adjust height if necessary
  backgroundColor: '#f0f2f5',
});

const TableContainerStyled = styled(TableContainer)({
  flexGrow: 1,
  overflowX: 'auto',
  overflowY: 'auto',
});

const FiltersContainer = styled('div')({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#fff',
  padding: '0.3rem',
  borderTop: '1px solid #ccc',
});

const TableRowStyled = styled(TableRow)<TableRowProps>(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  cursor: 'pointer', // Indicate the row is clickable
  '&:hover': {
    backgroundColor: '#ffffe0', // Light yellow on hover
  },
  '&.Mui-selected': {
    backgroundColor: '#ffffe0', // Light yellow when selected
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#ffffe0', // Maintain light yellow on hover when selected
  },
}));

const EODView: React.FC = () => {
  const currentMonth = new Date();
  const [location, setLocation] = useState('');
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [month, setMonth] = useState<string | null>(
    format(currentMonth, 'yyyy-MM')
  );
  const [startDate, setStartDate] = useState<string | null>(
    format(startOfMonth(currentMonth), 'yyyy-MM-dd')
  );
  const [endDate, setEndDate] = useState<string | null>(
    format(endOfMonth(currentMonth), 'yyyy-MM-dd')
  );
  const [data, setData] = useState<ApiResponse[]>([]);
  const [tenderHeaders, setTenderHeaders] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, month, startDate, endDate]);

  const fetchData = async () => {
    if (!location) return;
  
    const requestBody: any = { location };
  
    if (month) {
      const parsedDate = parseISO(`${month}-01`);
      requestBody.startDate = `${format(startOfMonth(parsedDate), 'yyyy-MM-dd')} 00:00:00`;
      requestBody.endDate = `${format(endOfMonth(parsedDate), 'yyyy-MM-dd')} 23:59:59`;
    } else if (startDate && endDate) {
      requestBody.startDate = startDate;
      requestBody.endDate = endDate;
    }
  
    try {
      const response = await fetch('/data/getData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const responseData: ApiResponse[] = await response.json();
      setData(responseData);
      processHeaders(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const preferredTenderOrder = [
    'Cash',
    'VISA',
    'Master',
    'Debit',
    'Amex',
    'Discover',
    'Foreign Currency',
    'Amex Disc',
    'Gift Card Sold',
    'Gift Card',
    'Store Credit-Issued\\Redeemed',
    'SVP Promo',
  ];
  
  // Map for custom descriptions
  const tenderMappings: { [key: string]: string } = {
    "Store Credit": "Store Credit",
    "Discovery": "Discover",  // any other custom mappings
    "Foriegn Currency": "Foreign Currency",  // mapping for misspellings
    "amex": "amex"  // mapping to align with preferred order casing
  };
  
  const processHeaders = (responseData: ApiResponse[]) => {
    const uniqueTenderTypes = new Set<string>();
  
    responseData.forEach((entry) => {
      entry.payments_by_tender.forEach((tender) => {
        // Map tender description if it exists in tenderMappings, otherwise use original
        const normalizedTenderDesc = tenderMappings[tender.tender_desc] || tender.tender_desc;
        uniqueTenderTypes.add(normalizedTenderDesc);
      });
    });
  
    // Sort headers based strictly on preferredTenderOrder
    const sortedHeaders = Array.from(uniqueTenderTypes).sort((a, b) => {
      const indexA = preferredTenderOrder.findIndex(order => order.toLowerCase() === a.toLowerCase());
      const indexB = preferredTenderOrder.findIndex(order => order.toLowerCase() === b.toLowerCase());
      // Place headers not in preferred order at the end
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });
  
    setTenderHeaders(sortedHeaders);
  };
  
  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setLocation(event.target.value);
  };

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    const selectedMonth = event.target.value;
    setMonth(selectedMonth);
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (date: string) => {
    setStartDate(date);
    setMonth(null);
  };

  const handleEndDateChange = (date: string) => {
    setEndDate(date);
    setMonth(null);
  };

  // Updated formatCurrency function to display negative values
 // Updated formatCurrency function to handle undefined or null values safely
const formatCurrency = (value: number | null | undefined) =>
  value !== null && value !== undefined
    ? `$${value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : '-';


  const generateMonthOptions = () => {
    const months = [];
    let date = subMonths(currentMonth, 11);

    for (let i = 0; i < 12; i++) {
      months.push({
        label: format(date, 'MMMM yyyy'),
        value: format(date, 'yyyy-MM'),
      });
      date = addMonths(date, 1);
    }

    return months;
  };

  // Function to calculate totals
  const calculateTotals = useMemo(() => {
    const totals: { [key: string]: number } = {
      net_sales: 0,
      hst: 0,
      gross_sales: 0,
      amex_disc: 0,
      total_rcvd: 0,
      actual_dpst: 0,
      rebate8: 0,
  statusIndianRebate8: 0,
      over_short: 0,
    };

    // Initialize tender headers totals
    tenderHeaders.forEach((header) => {
      totals[header] = 0;
    });

    data.forEach((day) => {
      totals.net_sales += day.net_sales;
      totals.hst += day.hst;
      totals.gross_sales += day.gross_sales;
      totals.rebate8 += day.rebate8;
      totals.statusIndianRebate8 += day.statusIndianRebate8;

      day.payments_by_tender.forEach((tender) => {
        const header = tender.tender_desc;
        if (totals[header] === undefined) {
          totals[header] = 0;
        }
        if (tender.total_payment_amount) {
          totals[header] += tender.total_payment_amount;
        }
      });

      const amexTender = day.payments_by_tender.find(
        (t) => t.tender_desc.toLowerCase() === 'amex'
      );
      if (amexTender && amexTender.amex_disc) {
        totals.amex_disc += amexTender.amex_disc;
      }

      // Total Received
      const totalReceived = day.payments_by_tender.reduce(
        (sum, tender) =>
          sum +
          (tender.tender_desc.toLowerCase() !== 'amex_disc'
            ? tender.total_payment_amount || 0
            : 0),
        0
      );
      totals.total_rcvd += totalReceived;

      // Actual DPST
      if (day.actual_dpst) {
        totals.actual_dpst += day.actual_dpst;
      }

      // Over/Short
      const overShort = day.gross_sales - totalReceived;
      totals.over_short += overShort;
    });

    return totals;
  }, [data, tenderHeaders]);


  const handleActualDpstChange = (
    date: string,
    newDpstValue: number | null
  ) => {
    setData((prevData) =>
      prevData.map((entry) =>
        entry.date === date ? { ...entry, actual_dpst: newDpstValue } : entry
      )
    );
  };

  const handleActualDpstBlur = async (
    date: string,
    actualDpst: number | null
  ) => {
    if (actualDpst === null) return;

    try {
      const response = await fetch('/data/saveActualDPST', {
        method: 'POST',
        credentials:'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location, date, actual_dpst: actualDpst }),
      });

      if (!response.ok) {
        throw new Error('Failed to save actual deposit');
      }

      console.log('Actual deposit saved successfully');
    } catch (error) {
      console.error('Error saving actual deposit:', error);
    }
  };

  const handleCashTenderBlur = async (date: string) => {
    const entry = data.find((d) => d.date === date);
    if (!entry) return;
    const tender = entry.payments_by_tender.find(
      (t) => t.tender_desc.toLowerCase() === 'cash'
    );
    if (!tender) return;

    const newAmount = tender.total_payment_amount;

    if (newAmount === null || newAmount === undefined) return;

    try {
      const response = await fetch('/data/updateCashPayment', {
        method: 'PUT',
        credentials:'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ location, startDate: date, newAmount }),
      });

      if (!response.ok) {
        throw new Error('Failed to update Cash payment amount');
      }

      console.log('Cash payment amount updated successfully');
    } catch (error) {
      console.error('Error updating Cash payment amount:', error);
    }
  };

  const handleCashTenderChange = (
    date: string,
    newAmount: number | null
  ) => {
    setData((prevData) =>
      prevData.map((entry) => {
        if (entry.date === date) {
          const updatedPaymentsByTender = entry.payments_by_tender.map(
            (tender) => {
              if (tender.tender_desc.toLowerCase() === 'cash') {
                return {
                  ...tender,
                  total_payment_amount: newAmount,
                };
              } else {
                return tender;
              }
            }
          );
          return {
            ...entry,
            payments_by_tender: updatedPaymentsByTender,
          };
        } else {
          return entry;
        }
      })
    );
  };

  return (
    <>
      <NavBar />
      <ThemeProvider theme={theme}>
        <Root>
          <TableContainerStyled>
            <Table
              stickyHeader
              style={{ tableLayout: 'auto', minWidth: 800 }}
              size="small"
            >
              <TableHead>
                {/* Totals Row */}
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {formatCurrency(calculateTotals.net_sales)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {formatCurrency(calculateTotals.hst)}
                    </Typography>
                  </TableCell>
          
                  <TableCell> <Typography variant="subtitle2" fontWeight="bold">{formatCurrency(calculateTotals.rebate8)}</Typography></TableCell>
                  <TableCell>  <Typography variant="subtitle2" fontWeight="bold">{formatCurrency(calculateTotals.statusIndianRebate8)}</Typography></TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {formatCurrency(calculateTotals.gross_sales)}
                    </Typography>
                  </TableCell>
                  {tenderHeaders.map((header, idx) => (
                    <TableCell key={`totals-${header}-${idx}`}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        {formatCurrency(calculateTotals[header])}
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {formatCurrency(calculateTotals.amex_disc)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {formatCurrency(calculateTotals.total_rcvd)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {formatCurrency(calculateTotals.actual_dpst)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {formatCurrency(calculateTotals.over_short)}
                    </Typography>
                  </TableCell>
                </TableRow>

                {/* Headers Row */}
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Net Sales</TableCell>
                  <TableCell>Taxes</TableCell>
                  <TableCell>Rebate 8%</TableCell>
                  <TableCell>Status Indian Rebate 8%</TableCell>
                  <TableCell>Total Sales</TableCell>
                  {tenderHeaders.map((header, idx) => (
  <TableCell key={`header-${idx}`}>{header}</TableCell>
))}

                  <TableCell>Amex Disc</TableCell>
                  <TableCell>Total RCVD</TableCell>
                  <TableCell>Pay-Out</TableCell>
                  <TableCell>Over/Short</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Typography variant="subtitle1" align="center">
                        No data available
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((day) => {
                    const totalReceived = day.payments_by_tender.reduce(
                      (sum, tender) =>
                        sum +
                        (tender.tender_desc.toLowerCase() !== 'amex_disc'
                          ? tender.total_payment_amount || 0
                          : 0),
                      0
                    );
                    const overShort = day.gross_sales - totalReceived;

                    return (
                      <TableRowStyled
                        key={day.date}
                        selected={selectedDate === day.date}
                        onClick={() =>
                          setSelectedDate((prevDate) =>
                            prevDate === day.date ? null : day.date
                          )
                        }
                      >
                        <TableCell>
                          {format(parseISO(day.date), 'MMM d')}
                        </TableCell>
                        <TableCell>{formatCurrency(day.net_sales)}</TableCell>
                        <TableCell>{formatCurrency(day.hst)}</TableCell>
                        <TableCell>{formatCurrency(day.rebate8)}</TableCell>
                        <TableCell>{formatCurrency(day.statusIndianRebate8)}</TableCell>
                        <TableCell>
                          {formatCurrency(day.gross_sales)}
                        </TableCell>
                        {tenderHeaders.map((header) => {
                          const tender = day.payments_by_tender.find(
                            (t) => t.tender_desc.toLowerCase() === header.toLowerCase()
                          );
                          if (header.toLowerCase() === 'cash') {
                            // Render TextField for Cash tender
                            return (
                              <TableCell key={`tender-${header}-${day.date}`}>
                                <TextField
                                  type="text"
                                  value={
                                    tender && tender.total_payment_amount !== null
                                      ? tender.total_payment_amount.toString()
                                      : ''
                                  }
                                  onChange={(e) =>
                                    handleCashTenderChange(
                                      day.date,
                                      e.target.value
                                        ? parseFloat(e.target.value)
                                        : null
                                    )
                                  }
                                  onBlur={() => handleCashTenderBlur(day.date)}
                                  variant="standard"
                                  size="small"
                                  inputProps={{
                                    style: {
                                      textAlign: 'center',
                                      fontSize: '0.875rem',
                                      fontFamily: 'Arial, sans-serif',
                                    },
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                  }}
                                />
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={`tender-${header}-${day.date}`}>
                                {formatCurrency(
                                  tender ? tender.total_payment_amount : null
                                )}
                              </TableCell>
                            );
                          }
                        })}
                        <TableCell>
                          {formatCurrency(
                            day.payments_by_tender.find(
                              (t) => t.tender_desc.toLowerCase() === 'amex'
                            )?.amex_disc || null
                          )}
                        </TableCell>
                        <TableCell>{formatCurrency(totalReceived)}</TableCell>
                        <TableCell>
                          <TextField
                            type="text"
                            value={
                              day.actual_dpst !== null
                                ? day.actual_dpst.toString()
                                : ''
                            }
                            onChange={(e) =>
                              handleActualDpstChange(
                                day.date,
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                            onBlur={() =>
                              handleActualDpstBlur(day.date, day.actual_dpst)
                            }
                            variant="standard"
                            size="small"
                            inputProps={{
                              style: {
                                textAlign: 'right',
                                fontSize: '0.875rem',
                                fontFamily: 'Arial, sans-serif',
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              disableUnderline: true,
                            }}
                          />
                        </TableCell>
                        <TableCell>{formatCurrency(overShort)}</TableCell>
                      </TableRowStyled>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainerStyled>

          <FiltersContainer>
            <Container>
              <Box
                sx={{
                  display: 'flex',
                  gap: '0.3rem',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <Select
                  value={location}
                  onChange={handleLocationChange}
                  displayEmpty
                  variant="outlined"
                  size="small"
                  sx={{
                    minWidth: '127px',
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Location
                  </MenuItem>
                  {locations.map((loc) => (
                    <MenuItem key={loc} value={loc}>
                      {loc}
                    </MenuItem>
                  ))}
                </Select>

                <Select
                  value={month || ''}
                  onChange={handleMonthChange}
                  displayEmpty
                  variant="outlined"
                  size="small"
                  sx={{
                    minWidth: '130px',
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Month
                  </MenuItem>
                  {generateMonthOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>

                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate || ''}
                  onChange={(e) => handleStartDateChange(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    sx: { fontSize: { xs: '0.75rem', sm: '0.875rem' } },
                  }}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  sx={{ minWidth: '130px' }}
                  inputProps={{
                    style: { fontSize: '0.75rem' },
                  }}
                />

                <TextField
                  label="End Date"
                  type="date"
                  value={endDate || ''}
                  onChange={(e) => handleEndDateChange(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    sx: { fontSize: { xs: '0.75rem', sm: '0.875rem' } },
                  }}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  sx={{ minWidth: '130px' }}
                  inputProps={{
                    style: { fontSize: '0.75rem' },
                  }}
                />

                <Button
                  variant="contained"
                  onClick={fetchData}
                  size="small"
                  sx={{
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  }}
                >
                  Get Data
                </Button>

                <Button
  variant="contained"
  onClick={() => exportToExcel(data, tenderHeaders, calculateTotals, location, month || '')}
  size="small"
  sx={{
    fontSize: { xs: '0.75rem', sm: '0.875rem' },
  }}
>
  Export to Excel
</Button>
              </Box>
            </Container>
          </FiltersContainer>
        </Root>
      </ThemeProvider>
    </>
  );
};

export default EODView;
